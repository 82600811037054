import { defineMessages } from 'react-intl';
const messages = defineMessages({
  buttonText: {
    id: 'buttonText',
    defaultMessage: 'Botoiaren testua',
  },
  buttonLink: {
    id: 'buttonLink',
    defaultMessage: 'Botoiaren esteka',
  },
  buttonLinkDescription: {
    id: 'buttonLinkDescription',
    defaultMessage: 'Botoiaren estekak nora bideratuko gaituen.',
  },
});

export const ButtonTextSchemaEnhancer = (props) => {
  const { intl, schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [
          ...schema.fieldsets[0].fields.filter(
            (field) => field !== 'buttonText' && field !== 'buttonLink',
          ),
          'buttonText',
          'buttonLink',
        ],
      },
    ],
    properties: {
      ...schema.properties,
      buttonText: {
        title: intl.formatMessage(messages.buttonText),
      },
      buttonLink: {
        title: intl.formatMessage(messages.buttonLink),
        description: intl.formatMessage(messages.buttonLinkDescription),
        widget: 'object_browser',
        mode: 'link',
        selectedItemAttrs: ['Title', 'Description', '@type', '@id'],
        allowExternals: true,
      },
    },
  };
};
