import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Label, Icon, Menu } from 'semantic-ui-react';
import { getQueryStringResultsBstart } from 'volto-ueu/actions';
// import { listTalks } from '../../actions';
import ikergazteSVG from 'volto-ueu/theme/assets/icons/ikergazte.svg';
import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { default as PIcon } from '@plone/volto/components/theme/Icon/Icon';
import qs from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';

import { flattenToAppURL } from '@plone/volto/helpers/Url/Url';
import moment from 'moment';
import { slugify } from '../../utils';
var _ = require('lodash');

const groupByDay = (e) => {
  const datetimeObj = new Date(`${e.start}`);
  const mmd = moment(datetimeObj);
  return `${mmd.format('MMMM')}k ${mmd.format('DD')}\n${mmd.format('dddd')}`;
};

const groupByHour = (e) => {
  const datetimeObj = new Date(`${e.start}`);
  const hour = `${datetimeObj.getUTCHours()}`;
  const min = `${datetimeObj.getUTCMinutes()}0`.slice(0, 2);
  return `${hour}${min}`;
};

const broupByTrack = (e) => {
  return e['@type'] === 'MiscEvent' ? 'misc-event' : e.track?.[0].token;
};

const Schedule = (props) => {
  const { miscEvent = [] } = props;
  const dispatch = useDispatch();
  let lang = useSelector((state) => state.intl.locale);
  moment.locale(lang);
  const subsite = useSelector(
    (state) => state.content?.data?.['@components']?.subsite,
  );
  const location = useLocation();
  const search = qs.parse(location.search);
  const history = useHistory();

  const iconDict = {
    mapsigns: 'map signs',
    acreditation: 'id card',
    registration: 'clipboard list',
    coffe: 'coffee',
    lunch: 'utensils',
    party: 'sign out',
    talk: 'microphone',
    Talk: 'microphone',
    Keynote: 'microphone',
  };

  React.useEffect(() => {
    dispatch(
      getQueryStringResultsBstart(
        flattenToAppURL(subsite?.['@id']),
        {
          query: [
            {
              i: 'portal_type',
              o: 'plone.app.querystring.operation.selection.any',
              v: ['Event'],
            },
          ],
          metadata_fields: '_all',
          sort_on: 'agenda_start',
          sort_order: 'ascending',
          b_size: 99999,
          limit: 99999,
        },
        'adaptedBaseQuery',
      ),
    );
  }, [dispatch, subsite]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rawTalks =
    useSelector(
      (state) => state.querystringsearch?.subrequests?.adaptedBaseQuery?.items,
    ) || [];
  const talks = React.useMemo(() => {
    const allEvent = [
      ...rawTalks.map((event, index) => {
        return {
          ...event,
          eventType: 'talk',
          track: [{ title: 'track-1', token: 'track-1' }],
        };
      }),
      ...miscEvent.map((event, index) => {
        return {
          ...event,
          '@type': 'MiscEvent',
        };
      }),
    ].filter((event, index) => {
      return event.start !== null && event.start !== undefined;
    });

    let resutTemp = _.chain(allEvent).groupBy(groupByDay).value();
    Object.keys(resutTemp).forEach((key, index) => {
      resutTemp[key] = _.groupBy(resutTemp[key], groupByHour);
    });
    return resutTemp;
  }, [rawTalks, miscEvent]);

  const talkCardGenerator = (data) => {
    return data.map((talk, index) => {
      const start = new Date(`${talk.start}`);
      const end = new Date(`${talk.end}`);
      const mmds = moment(start);
      const mmde = moment(end);

      return (
        <div className="talk-card" key={index}>
          <Label ribbon className="keynote">
            &nbsp;
          </Label>

          <div className="session-info">
            <div className="timing">
              <Icon name="clock outline" />
              {mmds.format('HH:mm')} - {mmde.format('HH:mm')}
            </div>
          </div>
          <div className="track-info">
            <div className="track-type">{talk.description}</div>
            <div className="talk-card-title">
              <UniversalLink href={flattenToAppURL(talk['@id'])}>
                {talk.title}
              </UniversalLink>
            </div>
            {talk.attendees && (
              <div className="attendees">
                {talk.attendees.map((persenter, index) => {
                  return (
                    <React.Fragment key={index}>
                      {persenter}
                      {talk.attendees.length !== index + 1 && ', '}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  const panes = Object.keys(talks).map((keyDay, index) => {
    return {
      index: index,
      key: slugify(keyDay),
      menuItem: (
        <Menu.Item
          key={`${keyDay}_menu`}
          style={{ whiteSpace: 'break-spaces' }}
          onClick={() => {
            history.replace({
              search: qs.stringify({
                ...qs.parse(location.search),
                eguna: slugify(keyDay),
              }),
            });
          }}
        >
          <div>{`${keyDay}`}</div>
        </Menu.Item>
      ),
      render: () => {
        return (
          <Tab.Pane className="tab-content">
            {Object.keys(talks[keyDay]).map((keyHour, index) => {
              const data = _.groupBy(talks[keyDay][keyHour], broupByTrack);
              const time = new Date(`${talks[keyDay][keyHour][0].start}`);
              return (
                <div
                  key={index}
                  className={`timeslot ${
                    data['misc-event']
                      ? `slot-misc-event ${data['misc-event'][0]['eventType']}`
                      : 'slot-talk'
                  }`}
                >
                  <div className="aside-indication">
                    <div className="icon-type">
                      {data[Object.keys(data)[0]][0].description &&
                      data[Object.keys(data)[0]][0].description
                        .toLowerCase()
                        .trim() === 'saio osagarria' ? (
                        <Icon name={iconDict.mapsigns} />
                      ) : iconDict[data[Object.keys(data)[0]][0].eventType] ? (
                        <Icon
                          name={
                            iconDict[data[Object.keys(data)[0]][0].eventType]
                          }
                        />
                      ) : (
                        <PIcon name={ikergazteSVG} />
                      )}
                    </div>
                    <div className="time-indication">
                      {moment(time).format('HH:mm')}
                    </div>
                  </div>
                  <div className="rooms">
                    {data['track-1'] && (
                      <div className="track track-1">
                        {data['track-1'] && talkCardGenerator(data['track-1'])}
                      </div>
                    )}
                    {data['track-2'] && (
                      <div className="track track-2">
                        {data['track-2'] && talkCardGenerator(data['track-2'])}
                      </div>
                    )}
                    {data['track-3'] && (
                      <div className="track track-3">
                        {data['track-3'] && talkCardGenerator(data['track-3'])}
                      </div>
                    )}

                    {data['misc-event'] && (
                      <div className="misc-event">
                        <div className="session-info">
                          <div className="timing">
                            <Icon name="clock outline" />
                            {}
                            {`${moment(
                              new Date(`${data['misc-event'][0].start}`),
                            ).format('HH:mm')} - ${moment(
                              new Date(`${data['misc-event'][0].end}`),
                            ).format('HH:mm')}`}
                          </div>
                        </div>
                        <div className="title">
                          {data['misc-event'][0].title}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </Tab.Pane>
        );
      },
    };
  });

  return (
    <div className="schedule">
      <Tab
        panes={panes}
        activeIndex={panes.find((pane) => pane.key === search.eguna)?.index}
        className="tab"
      />
    </div>
  );
};

export default Schedule;
