/**
 * PosterView view component.
 * @module components/theme/View/PosterView
 */
import React from 'react';
import { Segment, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';
import { queryRelations } from 'volto-ueu/actions';
import moment from 'moment';
import PropTypes from 'prop-types';

/**
 * PosterView view component class.
 * @function PosterView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PosterView = ({ content }) => {
  let lang = useSelector((state) => state.intl.locale);
  const dispatch = useDispatch();
  const related_events = useSelector(
    (state) => state.relations?.relations?.data?.posters?.items || [],
  );
  moment.locale(lang);

  React.useEffect(() => {
    dispatch(
      queryRelations(null, false, null, null, content.UID, null, null, [
        'start',
        'end',
      ]),
    );
  }, [dispatch, content.UID]);
  const presenters =
    content.poster_presenters.ikergazte_authors &&
    content.poster_presenters.ikergazte_authors.length > 0
      ? content.poster_presenters.ikergazte_authors.filter(
          (author) => author.presenter,
        )
      : [];
  const coauthors =
    content.poster_presenters.ikergazte_authors &&
    content.poster_presenters.ikergazte_authors.length > 0
      ? content.poster_presenters.ikergazte_authors.filter(
          (author) => !author.presenter,
        )
      : [];
  const affiliations = content.poster_presenters.affiliations;
  return (
    <div className="view-wrapper subsite-event">
      {content.title && <h1 className="subsite-title">{content.title}</h1>}
      <div className="poster-container">
        {(presenters.length > 0 || coauthors.length > 0) && (
          <div className="poster-authors">
            {presenters
              .map((p, key) => {
                return (
                  <span key={key}>
                    <u>{p.name}</u>
                    <sup>
                      {p.affiliations.map((aff, key) =>
                        key === 0 ? aff + 1 : `, ${aff + 1}`,
                      )}
                    </sup>
                  </span>
                );
              })
              .reduce((acc, cur) => [...acc, acc.length ? ', ' : '', cur], [])}
            {presenters.length > 0 && coauthors.length > 0 && ', '}
            {coauthors
              .map((ca, key) => {
                return (
                  <span key={key}>
                    {ca.name}
                    <sup>{ca.affiliations.map((aff) => aff + 1)}</sup>
                  </span>
                );
              })
              .reduce((acc, cur) => [...acc, acc.length ? ', ' : '', cur], [])}
          </div>
        )}
        {affiliations && affiliations.length > 0 && (
          <div className="poster-unis">
            {affiliations
              .map((aff, key) => {
                return (
                  <span key={key}>
                    <sup>{aff.id + 1}</sup>
                    {aff.name}
                  </span>
                );
              })
              .reduce((acc, cur) => [...acc, acc.length ? ', ' : '', cur], [])}
          </div>
        )}
        {presenters && presenters.length > 0 && (
          <div className="poster-emails">
            {presenters
              .map((p, key) => {
                return (
                  <UniversalLink href={`mailto:${p.email}`} key={key}>
                    {p.email}
                  </UniversalLink>
                );
              })
              .reduce((acc, cur) => [...acc, acc.length ? ', ' : '', cur], [])}
          </div>
        )}
        {presenters &&
          presenters.length === 0 &&
          coauthors &&
          coauthors.length > 0 && (
            <div className="poster-emails">
              {coauthors
                .map((p, key) => {
                  return (
                    <UniversalLink href={`mailto:${p.email}`} key={key}>
                      {p.email}
                    </UniversalLink>
                  );
                })
                .reduce(
                  (acc, cur) => [...acc, acc.length ? ', ' : '', cur],
                  [],
                )}
            </div>
          )}
        <h2 className="subsite-title">Laburpena</h2>
        {content.description && (
          <p className="poster-description">{content.description}</p>
        )}
        {content.doi && (
          <p className="poster-doi">
            DOI:{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://doi.org/${content.doi}`}
            >
              https://doi.org/{content.doi}
            </a>
          </p>
        )}
        {content?.keywords && content?.keywords?.length > 0 && (
          <div className="poster-keywords">
            Gako-hitzak:{' '}
            {content.keywords
              .map((ae, key) => <span key={key}>{ae}</span>)
              .reduce((prev, curr) => [prev, ', ', curr])}
          </div>
        )}
        {/* {content.taxonomy_unesco_categorization && (
          <CourseKnowledgeFieldsCard content={content} linked={true} />
          )} */}
      </div>
      {related_events.length > 0 &&
        related_events[0] &&
        related_events[0].source && (
          <Segment className="poster-event">
            <div className="talk-card">
              <div className="session-info">
                <div className="timing">
                  <Icon name="clock outline" />
                  {moment(new Date(`${related_events[0].source.start}`)).format(
                    'MMMM',
                  )}
                  k{' '}
                  {moment(new Date(`${related_events[0].source.start}`)).format(
                    'DD',
                  )}
                  {', '}
                  {moment(new Date(`${related_events[0].source.start}`)).format(
                    'HH:mm',
                  )}
                  -{' '}
                  {moment(new Date(`${related_events[0].source.end}`)).format(
                    'HH:mm',
                  )}
                </div>
              </div>
              <div className="track-info">
                <div className="track-type">
                  {related_events[0].source.description}
                </div>
                <div className="talk-card-title">
                  <UniversalLink href={related_events[0].source['@id']}>
                    {related_events[0].source.title}
                  </UniversalLink>
                </div>

                {related_events[0].source.attendees &&
                  related_events[0].source.attendees.length > 0 && (
                    <div className="attendees">
                      {related_events[0].source.attendees.map(
                        (persenter, index) => {
                          return (
                            <React.Fragment key={index}>
                              {persenter}
                              {related_events[0].source.attendees.length !==
                                index + 1 && ', '}
                            </React.Fragment>
                          );
                        },
                      )}
                    </div>
                  )}
              </div>
            </div>
          </Segment>
        )}
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PosterView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PosterView;
