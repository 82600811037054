import UrlWidget from '@plone/volto/components/manage/Widgets/UrlWidget';

import VoltoTagManager from 'volto-ueu/components/VoltoTagManager/VoltoTagManager';
import CheckboxWidget from 'volto-ueu/components/Widgets/CheckboxWidget';
import ReactDatepickerWidget from 'volto-ueu/components/Widgets/ReactDatepickerWidget';
import PersonBrowserWidget from 'volto-ueu/components/Widgets/PersonBrowserWidget';
import PosterBrowserWidget from 'volto-ueu/components/Widgets/PosterBrowserWidget';

import reducers from './reducers';
import applyBlocks from './blocks';
import applyViews from './views';
import applyRoutes from './routes';
import IkergazteAuthorsWidget from './components/Widgets/IkergazteAuthorsWidget';

const applyConfig = (config) => {
  const blocksConfig = {
    restricted: [
      'imagesGrid',
      'hero',
      'metadata',
      'metadataSection',
      'html',
      'csFeatured',
    ],
    mostUsed: ['slate', '-video'],
  };
  config.experimental.addBlockButton.enabled = true;
  config.settings = {
    ...config.settings,
    supportedLanguages: ['eu'],
    defaultLanguage: 'eu',
    navDepth: 2,
    cookiebotDomainGroupId: '96ef58be-2d6d-40cf-ae79-cdd4fd30de30',
    matomoSiteId: '10',
    matomoUrlBase: 'https://matomo.ueu.eus/',
    querystringSearchGet: true,
    importerRoles: ['Manager', 'Site Administrator'],
    pictureOptions: {
      grid: [
        { media: '(min-width: 768px)', image: 'preview' },
        { media: '(max-width: 767px)', image: 'large' },
      ],
      newsitem: [
        { media: '(min-width: 1200px)', image: 'larger' },
        { media: '(min-width: 992px) and (max-width: 1199px)', image: 'large' },
        { media: '(max-width: 991px)', image: 'teaser' },
      ],
      txiotesia: [
        { media: '(min-width: 1200px)', image: 'larger' },
        { media: '(min-width: 992px) and (max-width: 1199px)', image: 'large' },
        { media: '(max-width: 991px)', image: 'teaser' },
      ],
    },
    registry: {
      ...config.settings.registry,
      oinarrizko_deskontua:
        'ueu.addon.deskontuak_control_panel.oinarrizko_deskontua',
      bazkide_deskontua: 'ueu.addon.deskontuak_control_panel.bazkide_deskontua',
    },
    useEmailAsLogin: true,
    downloadableObjects: [],
    viewableInBrowserObjects: ['File'],
    pluggableStylesBlocksBlacklist: blocksConfig.restricted,
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: VoltoTagManager,
        props: {
          gtmCode: 'GTM-MF9S857',
        },
      },
    ],
  };
  config.blocks.blocksConfig.__grid.restricted = true;

  applyRoutes(config);
  applyViews(config);
  applyBlocks(config, blocksConfig);
  config.widgets.id = {
    ...config.widgets.id,
    author_link: UrlWidget,
    authors: PersonBrowserWidget,
    ikergazte_authors: IkergazteAuthorsWidget,
    posters: PosterBrowserWidget,
  };
  config.widgets.type = {
    ...config.widgets.type,
    required_boolean: CheckboxWidget,
    react_datepicker: ReactDatepickerWidget,
  };
  // config.widgets.widget = {
  //   ...config.widgets.widget,
  //   ikergazte_authors_widget: IkergazteAuthorsWidget,
  // };
  config.addonReducers = {
    ...config.addonReducers,
    ...reducers,
  };

  return config;
};

export default applyConfig;
