import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Segment,
  Divider,
  Grid,
  Header,
  Button,
  Popup,
} from 'semantic-ui-react';
import shareSVG from '@plone/volto/icons/share.svg';

import config from '@plone/volto/registry';
import SocialSharing from '@codesyntax/volto-social-sharing/SocialSharing';

import { getCourses, updateCourse } from 'volto-ueu/actions';
import { UeuModal } from 'volto-ueu/components';
import UeuListing from 'volto-ueu/components/Variations/ListingBlock/UeuListing';
import CourseInfoCard from './CourseInfoCard';
import CourseKnowledgeFieldsCard from './CourseKnowledgeFieldsCard';
import CourseTabsCard from './CourseTabsCard';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import { sharePositions, socialElements } from '../../../helpers/share';

const CourseView = ({ content, location }) => {
  const dispatch = useDispatch();

  const courses = useSelector((state) => state.courses.courses);
  const updateCourseState = useSelector((state) => state.updateCourse);
  let updateCourseState_ref = useRef(updateCourseState);
  const user = useSelector((state) => state.users.user);
  const ikastaroa_path = location.pathname;

  useEffect(() => {
    content?.taxonomy_unesco_categorization &&
      dispatch(
        getCourses(
          3,
          content.taxonomy_unesco_categorization.map((t_cat) => {
            return t_cat.token;
          }),
        ),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, content.UID]);
  useEffect(() => {
    updateCourseState_ref.current = updateCourseState;
  }, [updateCourseState]);
  const [currentUrl, setCurrentUrl] = React.useState('');
  useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);
  // MOBILE checker
  const [isMobile, setIsMobile] = React.useState(false);

  function handleWindowSizeChange() {
    setIsMobile(window?.innerWidth <= 768);
  }
  useEffect(() => {
    handleWindowSizeChange();
    window && window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window && window.removeEventListener('resize', handleWindowSizeChange);
    };
  });
  // end - MOBILE checker

  return (
    <Container className="view-wrapper">
      <Grid>
        <Grid.Column width={11}>
          <Header as="h1">{content?.title}</Header>
        </Grid.Column>
        <Grid.Column width={1}>
          <SocialSharing
            location={location}
            bannerPosition={sharePositions}
            socialElements={socialElements}
          />
          {!isMobile && (
            <Popup
              content={
                <ul className="share">
                  {socialElements
                    .filter((se) => !['tg', 'wa'].includes(se.id))
                    .map((se, key) => {
                      return (
                        <li key={key}>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              se.id !== 'mail'
                                ? `${se.sharing_url}${currentUrl}`
                                : `${se.sharing_url}[UEU] ${content.title}&body=${currentUrl}`
                            }
                          >
                            {se.name}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              }
              on="click"
              popper={{ id: 'popper-container', style: { zIndex: 2000 } }}
              position="bottom right"
              trigger={
                <Icon
                  name={shareSVG}
                  size="30px"
                  style={{ cursor: 'pointer' }}
                />
              }
            />
          )}
        </Grid.Column>
      </Grid>
      {user?.roles &&
        config.settings.importerRoles.find((element) =>
          user?.roles.includes(element),
        ) && (
          <Segment
            color="pink"
            className="admin-segment"
            loading={updateCourseState.loading}
          >
            Ikastaro honen datuak Kudeutik eguneratzeko:
            <UeuModal
              header="Ikastaroaren datuak Kudeu-tik eguneratu"
              closeAction={{
                closeText: 'Itxi eguneratu gabe',
              }}
              okAction={{
                okText: 'Bai, Kudeutik datuak ekarri',
                okOnClick: () => {
                  dispatch(updateCourse(ikastaroa_path));
                },
              }}
              trigger={() => {
                return (
                  <Button className="ueu primary">Eguneratu kudeutik</Button>
                );
              }}
            >
              <p>
                Kudeuko datu basean ikastaro honi dagozkion datuak ekartzera
                zoaz.
              </p>
              <p>
                Kontu izan, orain dauden datuak eguneratu egingo direla eta
                berriak ikusiko dituzula hemendik aurrera.
              </p>
              <p>
                <strong>
                  Ziur zaude Kudeutik datuak ekarri nahi dituzula?
                </strong>
              </p>
            </UeuModal>
            {!updateCourseState.loading && updateCourseState.error && (
              <Segment inverted color="red" tertiary>
                Errore bat egon da ikastaroaren datuak ekartzerakoan.
                <br />
                <br />
                <strong>Errore mezu originala:</strong>
                <br />
                {updateCourseState.error}
              </Segment>
            )}
            {updateCourseState_ref.current.loading &&
              updateCourseState.loaded &&
              !updateCourseState.error && (
                <Segment inverted color="green" tertiary>
                  {updateCourseState.message}
                </Segment>
              )}
          </Segment>
        )}
      <CourseInfoCard content={content} />
      <CourseTabsCard
        content={content}
        dispatch={dispatch}
        location={location}
      />
      {/* {isEntryInTime(content) && (
        <UniversalLink
          className="ui primary button ueu"
          href={`${content['@id']}${IKASTAROA_FORM_ROUTE}`}
        >
          Matrikulatu
        </UniversalLink>
      )} */}
      <Divider />
      <CourseKnowledgeFieldsCard content={content} />
      {courses.length > 0 && (
        <UeuListing
          items={courses
            .filter((course) => course.UID !== content.UID)
            .slice(0, 2)}
          header="JARDUERA GEHIAGO"
          howManyColumns={2}
          CardComponent={'Course'}
          isEditMode={false}
          showUnconditionally={true}
        />
      )}
    </Container>
  );
};

export default CourseView;
