import { GET_COURSES, UPDATE_COURSE, GET_MY_COURSES } from '../../actions';
// import { map } from 'lodash';
const getInitialState = {
  error: null,
  loaded: false,
  loading: false,
  courses: [],
};

/**
 * courses reducer.
 * @function courses
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export const getCoursesReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${GET_COURSES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        courses: [],
      };
    case `${GET_COURSES}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        courses: [],
      };
    case `${GET_COURSES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        courses: action.result.items,
      };
    default:
      return state;
  }
};

const getUpdateInitialState = {
  error: null,
  loaded: false,
  loading: false,
};

export const updateCourseReducer = (
  state = getUpdateInitialState,
  action = {},
) => {
  switch (action.type) {
    case `${UPDATE_COURSE}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case `${UPDATE_COURSE}_FAIL`:
      return {
        ...state,
        error: action.error.message,
        loaded: false,
        loading: false,
      };
    case `${UPDATE_COURSE}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        message: action.result,
      };
    default:
      return state;
  }
};

export const getMyCoursesReducer = (state = getInitialState, action = {}) => {
  switch (action.type) {
    case `${GET_MY_COURSES}_PENDING`:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
        courses: [],
      };
    case `${GET_MY_COURSES}_FAIL`:
      return {
        ...state,
        error: true,
        loaded: false,
        loading: false,
        courses: [],
      };
    case `${GET_MY_COURSES}_SUCCESS`:
      return {
        ...state,
        error: null,
        loaded: true,
        loading: false,
        courses: action.result,
      };
    default:
      return state;
  }
};
