export const TitleSchemaEnhancer = (props) => {
  const { schema } = props;
  return {
    ...schema,
    fieldsets: [
      {
        ...schema.fieldsets[0],
        fields: [
          ...schema.fieldsets[0].fields.filter((field) => field !== 'title'),
          'title',
        ],
      },
    ],
  };
};
