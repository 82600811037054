import React from 'react';

import ObjectListWidget from '@plone/volto/components/manage/Widgets/ObjectListWidget';

const ItemSchema = {
  title: 'Autorea',
  properties: {
    name: {
      title: 'Izen abizenak',
      description: 'Autorearen izen abizenak',
      type: 'string',
    },
    email: {
      title: 'Helbide elektronikoa',
      description: '',
      type: 'string',
    },
    uni: {
      title: 'Filiazioa',
      description: 'Autore honen filiazioa',
      type: 'string',
    },
    presenter: {
      title: 'Aurkezlea da?',
      description: 'Markatu autore hau aurkezlea bada',
      type: 'boolean',
    },
  },
  fieldsets: [
    {
      id: 'default',
      title: 'Autorea',
      fields: ['name', 'email', 'uni', 'presenter'],
    },
  ],
  required: [],
};

const IkergazteAuthorsWidget = (props) => {
  return (
    <ObjectListWidget
      schema={ItemSchema}
      {...props}
      value={props.value?.items || props.default?.items || []}
      onChange={(id, value) => props.onChange(id, { items: value })}
    />
  );
};

export default IkergazteAuthorsWidget;
